<template>
  <header class="mx-4">
    <nav
      class="container mx-auto flex items-center justify-between border-b border-b-primary py-4"
    >
      <!-- Logo and branding -->
      <ChauTechLogo />
      <!-- Navigation Links for Desktop -->
      <WebHeader :menuItems="headerMenuItems" v-on:scrollTo="scrollToSection" />
      <div class="flex items-center justify-between">
        <!-- Hamburger Menu Icon for Mobile -->
        <button
          class="mx-4 flex items-center rounded border-2 px-3 py-2 transition duration-500 hover:border-primary hover:text-primary md:hidden"
          @click="toggleMobileMenu"
        >
          <!-- Hamburger Icon -->
          <svg
            class="h-3 w-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0zM0 9h20v2H0zM0 15h20v2H0z" />
          </svg>
        </button>

        <ThemeToggle />

        <!-- Language drop down -->
        <LanguageSelector />
      </div>

      <!-- Mobile Menu -->
      <MobileHeader
        :isVisible="isMobileMenuVisible"
        :menuItems="headerMenuItems"
        v-on:update:is-visible="(val) => (isMobileMenuVisible = val)"
        v-on:scrollTo="scrollToSection"
      />
    </nav>
  </header>
</template>

<script setup lang="ts">
import { ref, defineEmits } from "vue";

import ChauTechLogo from "@/components/common/ChauTechLogo.vue";
import LanguageSelector from "@/components/common/LanguageSelector.vue";
import { headerMenuItems } from "@/utils/appConstants";
import MobileHeader from "./MobileHeader.vue";
import WebHeader from "./WebHeader.vue";
import ThemeToggle from "../common/ThemeToggle.vue";

const emit = defineEmits(["scrollTo"]);
const isMobileMenuVisible = ref(false);

const scrollToSection = (sectionId: string) => {
  isMobileMenuVisible.value = false;
  emit("scrollTo", sectionId);
};

const toggleMobileMenu = () => {
  isMobileMenuVisible.value = !isMobileMenuVisible.value;
};
</script>

<style scoped></style>
