import { App } from "vue";
import AOS from "aos";

export default {
  install(app: App) {
    app.directive("aos", {
      mounted(el, binding) {
        // Here you can set custom AOS attributes based on binding value
        // For example: binding.value could be "fade-up" or { animation: 'fade-up', delay: 100 }
        if (typeof binding.value === "string") {
          el.setAttribute("data-aos", binding.value);
        } else if (
          typeof binding.value === "object" &&
          binding.value.animation
        ) {
          el.setAttribute("data-aos", binding.value.animation);
          Object.keys(binding.value).forEach((key) => {
            if (key !== "animation") {
              el.setAttribute(`data-aos-${key}`, binding.value[key]);
            }
          });
        }
        AOS.refresh();
      },
    });
  },
};
