<template>
  <div
    class="main"
    v-aos="{
      animation: 'fade-up',
      anchorPlacement: 'top-center',
      duration: 1000,
    }"
  >
    <div class="text-content">
      {{ $t("hero.pre-text") }}
      <span class="highlight">Recruiting/Consulting</span>
      {{ $t("hero.post-text") }}
    </div>
  </div>
</template>

<script setup lang="ts"></script>
<style scoped>
.main {
  background-image: url("../../assets/images/hero/hero.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @apply relative h-[25vh] xxxs:h-[30vh] xs:h-[38vh] md:h-[50vh] lg:h-[60vh] xl:h-[90vh];
}

.text-content {
  @apply absolute left-[5%] top-[10%] w-[45%] text-xl font-bold dark:text-white xxxs:left-[10%] xxxs:top-[25%] sm:left-[10%] sm:top-[30%] sm:text-3xl md:text-4xl lg:text-5xl;
}

.highlight {
  @apply text-logo-green dark:text-logo-yellow;
}
</style>
