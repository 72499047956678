// Define the form data structure
export interface FormDataType {
  name: string;
  phone: string;
  email: string;
  message: string;
}

export enum LANG {
  EN = "en",
  DE = "de",
}

type CardSubTextType = {
  text: string;
  secondText: string;
  thirdText: string;
  textImagePath: string;
  categories: string[];
};

export type CardTextType = {
  title: string;
  titleImagePath: string;
  textData: CardSubTextType[];
};
