<template>
  <button
    href="#"
    class="whitespace-nowrap rounded-3xl border-2 border-transparent bg-primary px-6 py-2 text-sm font-semibold text-white transition duration-700 ease-in-out hover:border-primary hover:bg-white hover:text-primary"
    @click.prevent="emitClick"
  >
    {{ buttonTitle }}
    <span
      class="arrow ml-2"
      :style="{ display: arrowVisible ? 'inline-block' : 'none' }"
      >&rarr;</span
    >
  </button>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from "vue";

defineProps({
  buttonTitle: {
    type: String,
    required: true,
  },
  arrowVisible: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["click"]);

const emitClick = () => {
  emit("click");
};
</script>

<style scoped>
.arrow {
  font-size: 1.25rem;
  transition: transform 0.5s ease-in-out;
  display: inline-block;
}

a:hover .arrow {
  transform: translateX(5px);
}
</style>
