<template>
  <div class="relative inline-block">
    <!-- Flag Button -->
    <button
      class="flex items-center transition duration-500"
      @click="toggleDropDown"
    >
      <img :src="selectedFlag" alt="Selected Flag" class="h-6 w-6" />
    </button>

    <!-- Select Flags -->
    <transition
      enter-active-class="transition ease-out duration-300"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-if="isDropDownVisible"
        v-click-away="onClickAway"
        class="absolute left-0 right-0 z-50 rounded-md"
      >
        <img
          v-for="item in flagList"
          :src="item.imgPath"
          :alt="item.id"
          :key="item.id"
          class="my-2 h-6 w-6 cursor-pointer"
          @click="selectFlag(item)"
        />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { LANG } from "@/utils/types";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const flagList = [
  {
    id: "Germany",
    imgPath: require(`@/assets/images/flags/germany.png`),
    imgName: "germany.png",
    locale: LANG.DE,
  },
  {
    id: "United Kingdom",
    imgPath: require(`@/assets/images/flags/united-kingdom.png`),
    imgName: "united-kingdom.png",
    locale: LANG.EN,
  },
];

const isDropDownVisible = ref(false);
const selectedFlag = ref(require(`@/assets/images/flags/germany.png`));
const { locale } = useI18n();

const toggleDropDown = () => {
  isDropDownVisible.value = !isDropDownVisible.value;
};

const selectFlag = (flag: { imgName: string; locale: string }) => {
  selectedFlag.value = require(`@/assets/images/flags/${flag.imgName}`);
  locale.value = flag.locale;
  isDropDownVisible.value = false;
};

const onClickAway = () => {
  isDropDownVisible.value = false;
};
</script>
