<template>
  <div v-if="showConsentBanner" class="relative z-10">
    <div
      x-show="open"
      x-transition:enter-start="opacity-0 scale-90"
      x-transition:enter="transition duration-200 transform ease"
      x-transition:leave="transition duration-200 transform ease"
      x-transition:leave-end="opacity-0 scale-90"
      class="fixed inset-x-5 bottom-16 mx-auto flex max-w-screen-lg flex-wrap items-center justify-center gap-4 rounded-lg bg-white p-5 text-center drop-shadow-2xl md:flex-nowrap md:justify-between md:text-left"
    >
      <div class="flex flex-col items-center justify-center md:items-start">
        <div class="w-full">
          {{ $t("cookie.text") }}
        </div>
        <div class="flex">
          <div class="information" @click.native="openInNewTab('/datenschutz')">
            Datenschutz
          </div>
          <div class="information" @click.native="openInNewTab('/impressum')">
            Impressum
          </div>
        </div>
      </div>
      <div class="flex flex-shrink-0 items-center gap-4">
        <button
          @click="declineConsent"
          class="text-green-600 hover:underline focus:outline-none"
        >
          {{ $t("button.decline") }}
        </button>
        <button
          @click="giveConsent"
          class="rounded-md bg-green-500 px-5 py-2 text-white hover:bg-green-700 focus:outline-none"
        >
          {{ $t("button.allow") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const showConsentBanner = ref(true);

const checkConsent = () => {
  const consent = localStorage.getItem("cookieConsent");
  if (consent) {
    showConsentBanner.value = false;
  }
};

const giveConsent = () => {
  localStorage.setItem("cookieConsent", "true");
  showConsentBanner.value = false;
};

const declineConsent = () => {
  localStorage.setItem("cookieConsent", "false");
  showConsentBanner.value = false;
};

onMounted(checkConsent);

const openInNewTab = (url: string) => {
  const routeData = router.resolve({ path: url });
  window.open(routeData.href, "_blank");
};
</script>
<style scoped>
.information {
  @apply m-2 flex cursor-pointer items-center font-bold underline transition duration-300 hover:text-primary;
}
</style>
