<template>
  <div>
    <Divider />
    <div
      class="mb-8 flex flex-col items-center justify-center bg-green-200 p-8 text-center sm:flex-row sm:justify-between sm:text-left"
    >
      <h2 class="mx-auto mb-8 sm:mb-0">
        Impressum / Verantwortlicher Herausgeber
      </h2>
      <AppButton button-title="HomePage" @click="goToHome" />
    </div>
    <div class="container mx-auto p-8 dark:text-white">
      <div class="content">
        <p>
          <strong class="text-primary">ChauTech Consulting GmbH</strong><br />
          Lachnerstraße 1a<br />
          22083 Hamburg
        </p>

        <p>
          Telefon: +49 170 8266951<br />
          E-Mail:
          <a href="mailto:shuhaib.chaudhry@chautech.de"
            >shuhaib.chaudhry@chautech.de</a
          >
        </p>

        <p>Vertretungsberechtigter Geschäftsführer: Shuhaib Chaudhry</p>

        <p>
          Eingetragen im Handelsregister<br />
          Registergericht: Amtsgericht Hamburg<br />
          Registernummer: HRB 184844<br />
          USt-ID DE36 6522 677
        </p>

        <p>
          Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE
          XXX
        </p>

        <p>
          Verantwortlich für den Internetauftritt / Inhalt (gem. § 55 Abs. 2
          RStV):<br />
          Shuhaib Chaudhry<br />
          Lachnerstraße 1a<br />
          22083 Hamburg
        </p>

        <p class="mt-4">
          Haftungshinweis:<br />
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
          für die Inhalte externer Links. <br />Für den Inhalt der verlinkten
          Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>

        <h2 class="mt-6 text-xl font-semibold text-primary">
          Zuständige Aufsichtsbehörde
        </h2>
        <p>
          Agentur für Arbeit Hamburg<br />
          20070 Hamburg<br />
          Email:
          <a href="mailto:Hamburg@arbeitsagentur.de"
            >Hamburg@arbeitsagentur.de</a
          ><br />
          <a
            href="http://www.arbeitsagentur.de"
            target="_blank"
            rel="noopener noreferrer"
            >www.arbeitsagentur.de</a
          >
        </p>

        <h2 class="mt-6 text-xl font-semibold text-primary">
          Berufshaftpflichtversicherung
        </h2>
        <p>
          AXA Corporate Solutions<br />
          2-4, rue Jules Lefébvre / 52 rue d´Amsterdam<br />
          75009 Paris – France<br />
          Versicherungsnummer: XXX
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import AppButton from "@/components/common/AppButton.vue";
import Divider from "@/components/common/Divider.vue";

const router = useRouter();

const goToHome = () => {
  router.push("/");
};
</script>
