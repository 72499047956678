<template>
  <transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="opacity-0 scale-95"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="transition ease-in duration-300"
    leave-from-class="opacity-100 scale-100"
    leave-to-class="opacity-0 scale-95"
  >
    <div
      v-if="isVisible"
      v-click-away="onClickAway"
      class="mobile-menu absolute top-20 z-50 w-11/12 rounded-md bg-white p-4 shadow-md md:hidden"
    >
      <a
        v-for="item in menuItems"
        :key="item.id"
        href="#"
        class="duration-400 block rounded-md px-4 py-2 font-bold transition hover:bg-primary hover:text-white"
        @click="scrollToSection(item.id)"
        >{{ $t(item.title as string) }}</a
      >
    </div>
  </transition>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";
import { MenuItem } from "@/utils/appConstants";

defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  menuItems: {
    type: Array as () => MenuItem[],
    required: true,
  },
});

const emit = defineEmits(["scrollTo", "update:isVisible"]);

const scrollToSection = (sectionId: string) => {
  emit("scrollTo", sectionId);
};

const onClickAway = () => {
  emit("update:isVisible", false);
};
</script>

<style scoped>
.mobile-menu {
  left: 4%;
}
</style>
