<template>
  <div>
    <Divider height="6rem" />
    <SectionTitle :title="$t('section.contactUs')" />
    <section
      class="mb-16 flex flex-col items-center justify-around px-6 lg:flex-row"
    >
      <!-- Text Section -->
      <div
        class="md:2/5 mx-0 w-full self-start py-8 sm:w-9/12 md:mx-8 lg:mx-12 lg:w-2/5"
        v-aos="{
          animation: 'fade-up',
          anchorPlacement: 'top-center',
          duration: 1000,
        }"
      >
        <h1 class="mb-4 text-left dark:text-white">
          {{ $t("contactUs.heading") }}
        </h1>
        <p class="mb-6 text-left dark:text-white">
          {{ $t("contactUs.text") }}
        </p>
      </div>

      <!-- Form Section -->
      <div
        class="md:2/5 mx-0 w-full rounded-3xl rounded-bl-lg bg-green-200 p-8 sm:w-9/12 md:mx-8 lg:mx-14 lg:w-1/3"
        v-aos="{
          animation: 'fade-right',
          anchorPlacement: 'top-center',
          duration: 1000,
        }"
      >
        <form @submit.prevent="submitForm">
          <div class="-mx-2 mb-4 flex flex-wrap">
            <div class="mb-4 w-full px-2 md:mb-0 md:w-1/2">
              <input
                v-model="form.name"
                type="text"
                :placeholder="$t('contactUs.form.name')"
                class="w-full rounded-lg border-transparent p-4 focus:border-blue-500 focus:ring-0"
                required
              />
            </div>
            <div class="w-full px-2 md:w-1/2">
              <input
                v-model="form.phone"
                type="text"
                :placeholder="$t('contactUs.form.phone')"
                class="w-full rounded-lg border-transparent p-4 focus:border-blue-500 focus:ring-0"
                required
              />
            </div>
          </div>
          <div class="mb-4">
            <input
              v-model="form.email"
              type="email"
              :placeholder="$t('contactUs.form.email')"
              class="w-full rounded-lg border-transparent p-4 focus:border-blue-500 focus:ring-0"
              required
            />
          </div>
          <div class="mb-6">
            <textarea
              v-model="form.message"
              :placeholder="$t('contactUs.form.message')"
              rows="4"
              class="w-full rounded-lg border-transparent p-4 focus:border-blue-500 focus:ring-0"
              required
            ></textarea>
          </div>
          <div class="flex items-center justify-start">
            <AppButton
              :button-title="$t('button.submit')"
              :arrow-visible="false"
              @click="submitForm"
            />
            <div v-if="isFormSubmit !== undefined" class="ml-8">
              <span :style="{ color: isFormSubmit === true ? 'green' : 'red' }">
                {{
                  isFormSubmit === true
                    ? $t("contactUs.successfull")
                    : $t("contactUs.fail")
                }}
              </span>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import axios from "axios";
import SectionTitle from "./common/SectionTitle.vue";
import AppButton from "./common/AppButton.vue";
import Divider from "@/components/common/Divider.vue";
import { FormDataType } from "@/utils/types";

const isFormSubmit = ref<boolean | undefined>(undefined);

// Create a reactive form object
const form = ref<FormDataType>({
  name: "",
  phone: "",
  email: "",
  message: "",
});

// Function to handle form submission
const submitForm = async () => {
  const formData = new FormData();
  formData.append("name", form.value.name);
  formData.append("phone", form.value.phone);
  formData.append("email", form.value.email);
  formData.append("message", form.value.message);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  try {
    const response = await axios.post("./contact.php", formData, config);
    if (response.status === 200) {
      isFormSubmit.value = true;
      resetForm();

      setTimeout(() => {
        isFormSubmit.value = undefined;
      }, 4000);
    } else {
      isFormSubmit.value = false;
      resetForm();

      setTimeout(() => {
        isFormSubmit.value = undefined;
      }, 4000);
    }
  } catch (error) {
    isFormSubmit.value = false;

    setTimeout(() => {
      isFormSubmit.value = undefined;
    }, 4000);
  }
};

const resetForm = () => {
  form.value = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };
};
</script>
