import webDevelopment from "@/assets/images/consulting/web-development.png";
import sap from "@/assets/images/consulting/sap.png";
import experience from "@/assets/images/recruiting/experience.png";
import contacts from "@/assets/images/recruiting/contacts.png";
import globalNetwork from "@/assets/images/recruiting/global-network.png";
import independent from "@/assets/images/recruiting/independent.png";
import pooling from "@/assets/images/recruiting/pooling.png";
import candidatePooling from "@/assets/images/recruiting/c-pooling.png";
import profileDb from "@/assets/images/recruiting/profile-db.png";
import advice from "@/assets/images/recruiting/advice.png";
import automation from "@/assets/images/recruiting/automation.png";
import pulse from "@/assets/images/recruiting/pulse.png";
import delivery from "@/assets/images/recruiting/delivery.png";
import infrastructure from "@/assets/images/consulting/infrastructure.png";
import frontend from "@/assets/images/consulting/frontend.png";
import backend from "@/assets/images/consulting/backend.png";
import sapDev from "@/assets/images/consulting/sap-dev.png";
import cloud from "@/assets/images/consulting/cloud-computing.png";
import sapConsulting from "@/assets/images/consulting/sap-consulting.png";
import devOpsNew from "@/assets/images/consulting/devops-new.png";
import freelancer from "@/assets/images/recruiting/freelancer.png";
import itStaff from "@/assets/images/recruiting/it-staff.png";
import temp from "@/assets/images/recruiting/temporary.png";
import cosulting from "@/assets/images/recruiting/consulting.png";
import worldwide from "@/assets/images/recruiting/worldwide.png";
import process from "@/assets/images/recruiting/process.png";
import nearshore from "@/assets/images/recruiting/nearshore.png";
import pakistan from "@/assets/images/flags/pakistan.png";
import bulgaria from "@/assets/images/flags/bulgaria.png";
import romania from "@/assets/images/flags/romania.png";
import estonia from "@/assets/images/flags/estonia.png";
import ukraine from "@/assets/images/flags/ukraine.png";
import { CardTextType } from "./types";

export type MenuItem = {
  id: string;
  title: String;
};

export const headerMenuItems: MenuItem[] = [
  {
    id: "about-us",
    title: "menu.aboutUs",
  },
  {
    id: "services",
    title: "menu.ourServices",
  },
  {
    id: "contract",
    title: "menu.contractTypes",
  },
  {
    id: "team",
    title: "menu.team",
  },
];

export const recruitingKeywordList = [
  {
    id: 1,
    title: "recruiting.keywords.experience",
    imagePath: experience,
  },
  {
    id: 2,
    title: "recruiting.keywords.network",
    imagePath: globalNetwork,
  },
  {
    id: 3,
    title: "recruiting.keywords.contacts",
    imagePath: contacts,
  },
  {
    id: 4,
    title: "recruiting.keywords.advice",
    imagePath: advice,
  },
  {
    id: 5,
    title: "recruiting.keywords.process",
    imagePath: automation,
  },
  {
    id: 6,
    title: "recruiting.keywords.pulse",
    imagePath: pulse,
  },
];

export const recruitingTopics = [
  {
    id: 1,
    title: "recruiting.placement.freelancer",
    text: "recruiting.placement.technology",
    imagePath: freelancer,
  },
  {
    id: 2,
    title: "recruiting.placement.consultant",
    text: "",
    imagePath: itStaff,
  },
  {
    id: 3,
    title: "recruiting.placement.profile",
    text: "",
    imagePath: profileDb,
    makeHeading: true,
  },
  {
    id: 4,
    title: "recruiting.solution.consulting",
    text: "",
    imagePath: temp,
  },
  {
    id: 5,
    title: "recruiting.projects.process",
    text: "",
    imagePath: process,
  },
  {
    id: 6,
    title: "recruiting.projects.pooling",
    text: "",
    imagePath: candidatePooling,
  },
];

export const cardData: CardTextType[] = [
  {
    title: "consulting.development.heading",
    titleImagePath: webDevelopment,
    textData: [
      {
        text: "consulting.development.frontend",
        secondText: "consulting.development.backend",
        thirdText: "",
        textImagePath: frontend,
        categories: ["UX/UI Design", "JavaScript, TypeScript", "etc."],
      },
    ],
  },
  {
    title: "consulting.infrastructure.heading",
    titleImagePath: infrastructure,
    textData: [
      {
        text: "consulting.infrastructure.devOps",
        secondText: "consulting.infrastructure.cloud",
        thirdText: "consulting.infrastructure.security",
        textImagePath: devOpsNew,
        categories: ["IaC", "Deployment", "etc."],
      },
    ],
  },
  {
    title: "consulting.sap.heading",
    titleImagePath: sap,
    textData: [
      {
        text: "consulting.sap.development",
        secondText: "consulting.sap.consulting",
        thirdText: "",
        textImagePath: sapDev,
        categories: ["ABAP", "etc."],
      },
    ],
  },
];

export const consultingService = [
  {
    title: "consultingService.consulting",
    imagePath: cosulting,
  },
  {
    title: "consultingService.nearshore",
    imagePath: nearshore,
    flags: [
      {
        name: "country.ukraine",
        flag: ukraine,
      },
      {
        name: "country.romania",
        flag: romania,
      },
      {
        name: "country.bulgaria",
        flag: bulgaria,
      },
      {
        name: "country.estonia",
        flag: estonia,
      },
    ],
    // moreFlags: [
    //   {
    //     name: "country.bulgaria",
    //     flag: bulgaria,
    //   },
    //   {
    //     name: "country.estonia",
    //     flag: estonia,
    //   },
    // ],
  },
  {
    title: "consultingService.offshore",
    imagePath: worldwide,
    singleRow: [
      {
        name: "country.pakistan",
        flag: pakistan,
      },
    ],
  },
];
