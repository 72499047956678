<template>
  <div class="hidden items-center gap-8 md:flex">
    <a
      v-for="item in menuItems"
      :key="item.id"
      href="#"
      class="font-bold transition duration-500 hover:text-primary dark:text-white dark:hover:text-primary"
      @click="scrollToSection(item.id)"
      >{{ $t(item.title as string) }}</a
    >
  </div>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";

import { MenuItem } from "@/utils/appConstants";

const emit = defineEmits(["scrollTo"]);

defineProps({
  menuItems: {
    type: Array as () => MenuItem[],
    required: true,
  },
});

const scrollToSection = (sectionId: string) => {
  emit("scrollTo", sectionId);
};
</script>
