<template>
  <div class="w-36 sm:w-48">
    <img
      src="../../assets/images/company-logo/chautech-typeface.png"
      alt="chautech-logo"
    />
  </div>
</template>

<script setup lang="ts"></script>
