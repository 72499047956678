<template>
  <div>
    <Divider />
    <div
      class="mb-8 flex flex-col items-center justify-center bg-green-200 p-8 text-center sm:flex-row sm:justify-between sm:text-left"
    >
      <h2 class="mx-auto mb-8 sm:mb-0">Datenschutz</h2>
      <AppButton button-title="HomePage" @click="goToHome" />
    </div>
    <div class="container mx-auto p-8 dark:text-white">
      <p>
        Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des
        Datenschutzrechts (insb. gemäß BDSG n.F. und der europäischen
        Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang und Zweck
        der Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese
        Datenschutzerklärung gilt auch für unsere Websites und
        Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie etwa
        „personenbezogene Daten“ oder „Verarbeitung“ verweisen wir auf Art. 4
        DS-GVO.
      </p>
      <strong>Name und Kontaktdaten des / der Verantwortlichen</strong><br />
      Unser/e Verantwortliche/r (nachfolgend „Verantwortlicher“) i.S.d. Art. 4
      Zif. 7 DS-GVO ist:<br />
      <br />
      ChauTech Consulting GmbH<br />Lachnerstraße 1a<br />22083 Hamburg<br />Geschäftsführer
      Shuhaib Chaudhry <br />Handelsregister/Nr.: HRB 184844<br />Registergericht:
      Amtsgericht Hamburg<br />E-Mail-Adresse: info@chautech.de<br /><br />

      <strong
        >Datenarten, Zwecke der Verarbeitung und Kategorien betroffener
        Personen</strong
      ><br />
      <p>
        Nachfolgend informieren wir Sie über Art, Umfang und Zweck der Erhebung,
        Verarbeitung und Nutzung personenbezogener Daten.
      </p>
      <strong>1. Arten der Daten, die wir verarbeiten</strong><br />
      Nutzungsdaten (Zugriffszeiten, besuchte Websites etc.), Bestandsdaten
      (Name, Adresse etc.), Kontaktdaten (Telefonnummer, E-Mail, Fax etc.),
      Inhaltsdaten (Texteingaben, Videos, Fotos etc.), Kommunikationsdaten
      (IP-Adresse etc.), <br />
      <br />
      <strong>2. Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO</strong
      ><br />
      Beweiszwecke / Beweissicherung, Website technisch und wirtschaftlich
      optimieren, Leichten Zugang zur Website ermöglichen, Kontaktaufnahme bei
      juristischer Beanstandung durch Dritte, Erfüllung gesetzlicher
      Aufbewahrungspflichten, Optimierung und statistische Auswertung unserer
      Dienste, Kommerzielle Nutzung der Website unterstützen, Nutzererfahrung
      verbessern, Website benutzerfreundlich gestalten, Wirtschaftlicher Betrieb
      der Werbung und Website, Marketing / Vertrieb / Werbung, Erstellung von
      Statistiken,Abwicklung eines Bewerberverfahrens, Kundenservice und
      Kundenpflege, Kontaktanfragen abwickeln, Websites mit Funktionen und
      Inhalten bereitstellen, Maßnahmen der Sicherheit,
      Unterbrechungsfreier,sicherer Betrieb unserer Website, <br />
      <br />
      <strong
        >3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e)
        DS-GVO</strong
      ><br />
      Besucher/Nutzer der Website, Kunden, Interessenten, Bewerber,
      Beschäftigte, <br />
      <br />
      <p>
        Die betroffenen Personen werden zusammenfassend als „Nutzer“ bezeichnet.
      </p>
      <br />

      <strong>Rechtsgrundlagen der Verarbeitung personenbezogener Daten</strong>
      <p>
        Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der
        Verarbeitung personenbezogener Daten:
      </p>
      <ol style="margin: 10px 0px; padding: 15px">
        <li>
          Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen
          Daten eingeholt haben, ist Art. 6 Abs. 1 S. 1 lit. a) DS-GVO
          Rechtsgrundlage.
        </li>
        <li>
          Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur
          Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Ihre
          Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO
          Rechtsgrundlage.
        </li>
        <li>
          Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
          erforderlich, der wir unterliegen (z.B. gesetzliche
          Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO
          Rechtsgrundlage.
        </li>
        <li>
          Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der
          betroffenen Person oder einer anderen natürlichen Person zu schützen,
          so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO Rechtsgrundlage.
        </li>
        <li>
          Ist die Verarbeitung zur Wahrung unserer oder der berechtigten
          Interessen eines Dritten erforderlich und überwiegen diesbezüglich
          Ihre Interessen oder Grundrechte und Grundfreiheiten nicht, so ist
          Art. 6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage.
        </li>
      </ol>
      <br />
      <strong
        >Weitergabe personenbezogener Daten an Dritte und
        Auftragsverarbeiter</strong
      >
      <p>
        Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte
        weiter. Sollte dies doch der Fall sein, dann erfolgt die Weitergabe auf
        der Grundlage der zuvor genannten Rechtsgrundlagen z.B. bei der
        Weitergabe von Daten an Online-Paymentanbieter zur Vertragserfüllung
        oder aufgrund gerichtlicher Anordnung oder wegen einer gesetzlichen
        Verpflichtung zur Herausgabe der Daten zum Zwecke der Strafverfolgung,
        zur Gefahrenabwehr oder zur Durchsetzung der Rechte am geistigen
        Eigentum.<br />
        Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B. zum
        Webhosting unserer Websites und Datenbanken) zur Verarbeitung Ihrer
        Daten ein. Wenn im Rahmen einer Vereinbarung zur Auftragsverarbeitung an
        die Auftragsverarbeiter Daten weitergegeben werden, erfolgt dies immer
        nach Art. 28 DS-GVO. Wir wählen dabei unsere Auftragsverarbeiter
        sorgfältig aus, kontrollieren diese regelmäßig und haben uns ein
        Weisungsrecht hinsichtlich der Daten einräumen lassen. Zudem müssen die
        Auftragsverarbeiter geeignete technische und organisatorische Maßnahmen
        getroffen haben und die Datenschutzvorschriften gem. BDSG n.F. und
        DS-GVO einhalten
      </p>

      <br />
      <strong>Datenübermittlung in Drittstaaten</strong>
      <p>
        Durch die Verabschiedung der europäischen Datenschutz-Grundverordnung
        (DS-GVO) wurde eine einheitliche Grundlage für den Datenschutz in Europa
        geschaffen. Ihre Daten werden daher vorwiegend durch Unternehmen
        verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die
        Verarbeitung durch Dienste Dritter außerhalb der Europäischen Union oder
        des Europäischen Wirtschaftsraums stattfinden, so müssen diese die
        besonderen Voraussetzungen der Art. 44 ff. DS-GVO erfüllen. Das
        bedeutet, die Verarbeitung erfolgt aufgrund besonderer Garantien, wie
        etwa die von der EU-Kommission offiziell anerkannte Feststellung eines
        der EU entsprechenden Datenschutzniveaus oder der Beachtung offiziell
        anerkannter spezieller vertraglicher Verpflichtungen, der so genannten
        „Standardvertragsklauseln“. <br />Soweit wir aufgrund der Unwirksamkeit
        des sog. „Privacy Shields“, nach Art. 49 Abs. 1 S. 1 lit. a) DSGVO die
        ausdrückliche Einwilligung in die Datenübermittlung in die USA von Ihnen
        einholen, weisen wird diesbezüglich auf das Risiko eines geheimen
        Zugriffs durch US-Behörden und die Nutzung der Daten zu
        Überwachungszwecken, ggf. ohne Rechtsbehelfsmöglichkeiten für EU-Bürger,
        hin.
      </p>

      <br />
      <strong>Löschung von Daten und Speicherdauer</strong>
      <p>
        Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben,
        werden Ihre personenbezogen Daten gelöscht oder gesperrt, sobald die zur
        Verarbeitung erteilte Einwilligung von Ihnen widerrufen wird oder der
        Zweck für die Speicherung entfällt bzw. die Daten für den Zweck nicht
        mehr erforderlich sind, es sei denn deren weitere Aufbewahrung ist zu
        Beweiszwecken erforderlich oder dem stehen gesetzliche
        Aufbewahrungspflichten entgegenstehen. Darunter fallen etwa
        handelsrechtliche Aufbewahrungspflichten von Geschäftsbriefen nach § 257
        Abs. 1 HGB (6 Jahre) sowie steuerrechtliche Aufbewahrungspflichten nach
        § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn die vorgeschriebene
        Aufbewahrungsfrist abläuft, erfolgt eine Sperrung oder Löschung Ihrer
        Daten, es sei denn die Speicherung ist weiterhin für einen
        Vertragsabschluss oder zur Vertragserfüllung erforderlich.
      </p>

      <br />
      <strong>Bestehen einer automatisierten Entscheidungsfindung</strong>
      <p>
        Wir setzen keine automatische Entscheidungsfindung oder ein Profiling
        ein.
      </p>

      <br />
      <strong
        >Bereitstellung unserer Website und Erstellung von Logfiles</strong
      >
      <ol style="margin: 10px 0px; padding: 15px">
        <li>
          Wenn Sie unsere Webseite lediglich informatorisch nutzen (also keine
          Registrierung und auch keine anderweitige Übermittlung von
          Informationen), erheben wir nur die personenbezogenen Daten, die Ihr
          Browser an unseren Server übermittelt. Wenn Sie unsere Website
          betrachten möchten, erheben wir die folgenden Daten:<br />
          • IP-Adresse;<br />
          • Internet-Service-Provider des Nutzers;<br />
          • Datum und Uhrzeit des Abrufs;<br />
          • Browsertyp;<br />
          • Sprache und Browser-Version;<br />
          • Inhalt des Abrufs;<br />
          • Zeitzone;<br />
          • Zugriffsstatus/HTTP-Statuscode;<br />
          • Datenmenge;<br />
          • Websites, von denen die Anforderung kommt;<br />
          • Betriebssystem.<br />
          Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen
          Daten von Ihnen findet nicht statt.<br /><br />
        </li>
        <li>
          Diese Daten dienen dem Zweck der nutzerfreundlichen, funktionsfähigen
          und sicheren Auslieferung unserer Website an Sie mit Funktionen und
          Inhalten sowie deren Optimierung und statistischen Auswertung.<br /><br />
        </li>
        <li>
          Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes
          berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1
          lit. f) DS-GVO.<br /><br />
        </li>
        <li>
          Wir speichern aus Sicherheitsgründen diese Daten in Server-Logfiles
          für die Speicherdauer von 60 Tagen. Nach Ablauf dieser Frist werden
          diese automatisch gelöscht, es sei denn wir benötigen deren
          Aufbewahrung zu Beweiszwecken bei Angriffen auf die
          Serverinfrastruktur oder anderen Rechtsverletzungen.<br />
        </li>
      </ol>

      <br />
      <strong>Cookies</strong>
      <ol style="margin: 10px 0px; padding: 15px">
        <li>
          Wir verwenden sog. Cookies bei Ihrem Besuch unserer Website. Cookies
          sind kleine Textdateien, die Ihr Internet-Browser auf Ihrem Rechner
          ablegt und speichert. Wenn Sie unsere Website erneut aufrufen, geben
          diese Cookies Informationen ab, um Sie automatisch wiederzuerkennen.
          Zu den Cookies zählen auch die sog. „Nutzer-IDs“, wo Angaben der
          Nutzer mittels pseudonymisierter Profile gespeichert werden. Wir
          informieren Sie dazu beim Aufruf unserer Website mittels eines
          Hinweises auf unsere Datenschutzerklärung über die Verwendung von
          Cookies zu den zuvor genannten Zwecken und wie Sie dieser
          widersprechen bzw. deren Speicherung verhindern können („Opt-out“).<br /><br />
          <strong>Es werden folgende Cookie-Arten unterschieden:</strong
          ><br /><br />
          <strong>• Notwendige, essentielle Cookies:</strong> Essentielle
          Cookies sind Cookies, die zum Betrieb der Webseite unbedingt
          erforderlich sind, um bestimmte Funktionen der Webseite wie Logins,
          Warenkorb oder Nutzereingaben z.B. bzgl. Sprache der Webseite zu
          speichern.<br /><br />
          <strong>• Session-Cookies:</strong> Session-Cookies werden zum
          Wiedererkennen mehrfacher Nutzung eines Angebots durch denselben
          Nutzer (z.B. wenn Sie sich eingeloggt haben zur Feststellung Ihres
          Login-Status) benötigt. Wenn Sie unsere Seite erneut aufrufen, geben
          diese Cookies Informationen ab, um Sie automatisch wiederzuerkennen.
          Die so erlangten Informationen dienen dazu, unsere Angebote zu
          optimieren und Ihnen einen leichteren Zugang auf unsere Seite zu
          ermöglichen. Wenn Sie den Browser schließen oder Sie sich ausloggen,
          werden die Session-Cookies gelöscht.<br /><br />
          <strong>• Persistente Cookies:</strong> Diese Cookies bleiben auch
          nach dem Schließen des Browsers gespeichert. Sie dienen zur
          Speicherung des Logins, der Reichweitenmessung und zu
          Marketingzwecken. Diese werden automatisiert nach einer vorgegebenen
          Dauer gelöscht, die sich je nach Cookie unterscheiden kann. In den
          Sicherheitseinstellungen Ihres Browsers können Sie die Cookies
          jederzeit löschen.<br /><br />
          <strong
            >• Cookies von Drittanbietern (Third-Party-Cookies insb. von
            Werbetreibenden):</strong
          >
          Entsprechend Ihren Wünschen können Sie Ihre Browser-Einstellung
          konfigurieren und z. B. Die Annahme von Third-Party-Cookies oder allen
          Cookies ablehnen. Wir weisen Sie jedoch an dieser Stelle darauf hin,
          dass Sie dann eventuell nicht alle Funktionen dieser Website nutzen
          können. Lesen Sie Näheres zu diesen Cookies bei den jeweiligen
          Datenschutzerklärungen zu den Drittanbietern.<br /><br />
        </li>
        <li>
          <strong>Datenkategorien:</strong> Nutzerdaten, Cookie, Nutzer-ID (inb.
          die besuchten Seiten, Geräteinformationen, Zugriffszeiten und
          IP-Adressen).<br /><br />
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Die so erlangten
          Informationen dienen dem Zweck, unsere Webangebote technisch und
          wirtschaftlich zu optimieren und Ihnen einen leichteren und sicheren
          Zugang auf unsere Website zu ermöglichen.<br /><br />
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Wenn wir Ihre personenbezogenen
          Daten mit Hilfe von Cookies aufgrund Ihrer Einwilligung verarbeiten
          („Opt-in“), dann ist Art. 6 Abs. 1 S. 1 lit. a) DSGVO die
          Rechtsgrundlage. Ansonsten haben wir ein berechtigtes Interesse an der
          effektiven Funktionalität, Verbesserung und wirtschaftlichen Betrieb
          der Website, so dass in dem Falle Art. 6 Abs. 1 S. 1 lit. f) DS-GVO
          Rechtsgrundlage ist. Rechtsgrundlage ist zudem Art. 6 Abs. 1 S. 1 lit.
          b) DS-GVO, wenn die Cookies zur Vertragsanbahnung z.B. bei
          Bestellungen gesetzt werden.<br /><br />
        </li>
        <li>
          <strong>Speicherdauer/ Löschung:</strong> Die Daten werden gelöscht,
          sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
          erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung
          der Website ist dies der Fall, wenn die jeweilige Session beendet
          ist.<br /><br />Cookies werden ansonsten auf Ihrem Computer
          gespeichert und von diesem an unsere Seite übermittelt. Daher haben
          Sie als Nutzer auch die volle Kontrolle über die Verwendung von
          Cookies. Durch eine Änderung der Einstellungen in Ihrem
          Internetbrowser können Sie die Übertragung von Cookies deaktivieren
          oder einschränken. Bereits gespeicherte Cookies können jederzeit
          gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies
          für unsere Website deaktiviert, können möglicherweise nicht mehr alle
          Funktionen der Website vollumfänglich genutzt werden.<br /><br />
          <strong
            >Hier finden Sie Informationen zur L&ouml;schung von Cookies nach
            Browsern:</strong
          ><br /><br />
          <strong>Chrome:</strong>
          <a
            href="https://support.google.com/chrome/answer/95647"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://support.google.com/chrome/answer/95647</a
          ><br /><br />
          <strong>Safari:</strong>
          <a
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://support.apple.com/de-at/guide/safari/sfri11471/mac</a
          ><br /><br />
          <strong>Firefox:</strong>
          <a
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen</a
          ><br /><br />
          <strong>Internet Explorer:</strong>
          <a
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies</a
          ><br /><br />
          <strong>Microsoft Edge:</strong>
          <a
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies</a
          ><br /><br />
        </li>
        <li>
          <strong>Widerspruch und „Opt-Out“:</strong> Das Speichern von Cookies
          auf Ihrer Festplatte können Sie unabhängig von einer Einwilligung oder
          gesetzlichen Erlaubnis allgemein verhindern, indem Sie in Ihren
          Browser-Einstellungen „keine Cookies akzeptieren“ wählen. Dies kann
          aber eine Funktionseinschränkung unserer Angebote zur Folge haben. Sie
          können dem Einsatz von Cookies von Drittanbietern zu Werbezwecken über
          ein sog. „Opt-out“ über diese amerikanische
          Website (https://optout.aboutads.info) oder diese europäische Website
          <span class="text-[0.5rem] sm:text-base"
            >(http://www.youronlinechoices.com/de/praferenzmanagement/)
          </span>
          widersprechen.<br /><br />
        </li>
      </ol>

      <br />
      <strong>Kontaktaufnahme per Kontaktformular / E-Mail / Fax / Post</strong>
      <ol style="margin: 10px 0px; padding: 15px">
        <li>
          Bei der Kontaktaufnahme mit uns per Kontaktformular, Fax, Post oder
          E-Mail werden Ihre Angaben zum Zwecke der Abwicklung der
          Kontaktanfrage verarbeitet.<br /><br />
        </li>
        <li>
          Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
          Einwilligung von Ihnen Art. 6 Abs. 1 S. 1 lit. a) DS-GVO.
          Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
          Kontaktanfrage oder E-Mail, eines Briefes oder Faxes übermittelt
          werden, ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Der Verantwortliche hat
          ein berechtigtes Interesse an der Verarbeitung und Speicherung der
          Daten, um Anfragen der Nutzer beantworten zu können, zur
          Beweissicherung aus Haftungsgründen und um ggf. seiner gesetzlichen
          Aufbewahrungspflichten bei Geschäftsbriefen nachkommen zu können.
          Zielt der Kontakt auf den Abschluss eines Vertrages ab, so ist
          zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1
          lit. b) DS-GVO.<br /><br />
        </li>
        <li>
          Wir können Ihre Angaben und Kontaktanfrage in unserem
          Customer-Relationship-Management System ("CRM System") oder einem
          vergleichbaren System speichern.<br /><br />
        </li>
        <li>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen
          Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die
          per E-Mail übersandt wurden, ist dies dann der Fall, wenn die
          jeweilige Konversation mit Ihnen beendet ist. Beendet ist die
          Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass
          der betroffene Sachverhalt abschließend geklärt ist. Anfragen von
          Nutzern, die über einen Account bzw. Vertrag mit uns verfügen,
          speichern wir bis zum Ablauf von zwei Jahren nach Vertragsbeendigung.
          Im Fall von gesetzlichen Archivierungspflichten erfolgt die Löschung
          nach deren Ablauf: Ende handelsrechtlicher (6 Jahre) und
          steuerrechtlicher (10 Jahre) Aufbewahrungspflicht.<br /><br />
        </li>
        <li>
          Sie haben jederzeit die Möglichkeit, die Einwilligung nach Art. 6 Abs.
          1 S. 1 lit. a) DS-GVO zur Verarbeitung der personenbezogenen Daten zu
          widerrufen. Nehmen Sie per E-Mail Kontakt mit uns auf, so können Sie
          der Speicherung der personenbezogenen Daten jederzeit
          widersprechen.<br /><br />
        </li>
      </ol>

      <br />
      <strong>Kontaktaufnahme per Telefon</strong>
      <ol style="margin: 10px 0px; padding: 15px">
        <li>
          Bei der Kontaktaufnahme mit uns per Telefon wird Ihre Telefonnummer
          zur Bearbeitung der Kontaktanfrage und deren Abwicklung verarbeitet
          und temporär im RAM / Cache des Telefongerätes / Displays gespeichert
          bzw. angezeigt. Die Speicherung erfolgt aus Haftungs- und
          Sicherheitsgründen, um den Beweis des Anrufs führen zu können sowie
          aus wirtschaftlichen Gründen, um einen Rückruf zu ermöglichen. Im
          Falle von unberechtigten Werbeanrufen, sperren wir die Rufnummern.<br /><br />
        </li>
        <li>
          Rechtsgrundlage für die Verarbeitung der Telefonnummer ist Art. 6
          Abs. 1 S. 1 lit. f) DS-GVO. Zielt der Kontakt auf den Abschluss eines
          Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung
          Art. 6 Abs. 1 lit. b) DS-GVO.<br /><br />
        </li>
        <li>
          Der Gerätecache speichert die Anrufe 30 Tage und überschreibt bzw.
          löscht sukzessiv alte Daten, bei Entsorgung des Gerätes werden alle
          Daten gelöscht und der Speicher ggf. zerstört. Gesperrte Telefonnummer
          werden jährlich auf die Notwendigkeit der Sperrung geprüft.<br /><br />
        </li>
        <li>
          Sie können die Anzeige der Telefonnummer verhindern, indem Sie mit
          unterdrückter Telefonnummer anrufen.<br /><br />
        </li>
      </ol>

      <br />
      <strong>Präsenz in sozialen Medien</strong>

      <ol style="margin: 10px 0px; padding: 15px">
        <li>
          Wir unterhalten in sozialen Medien Profile bzw. Fanpages. Bei der
          Nutzung und dem Aufruf unseres Profils im jeweiligen Netzwerk durch
          Sie gelten die jeweiligen Datenschutzhinweise und Nutzungsbedingungen
          des jeweiligen Netzwerks.<br /><br />
        </li>
        <li>
          <strong
            >Datenkategorien und Beschreibung der Datenverarbeitung:</strong
          >
          Nutzungsdaten, Kontaktdaten, Inhaltsdaten, Bestandsdaten. Ferner
          werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall
          für Marktforschungs- und Werbezwecke verarbeitet. So können z.B.
          anhand des Nutzungsverhaltens und sich daraus ergebender Interessen
          der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können
          wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und
          außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der
          Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf
          den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten
          und die Interessen der Nutzer gespeichert werden. Ferner können in den
          Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten
          Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder
          der jeweiligen Plattformen sind und bei diesen eingeloggt sind). Für
          eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und
          der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
          Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
          Netzwerke. Auch im Fall von Auskunftsanfragen und der Geltendmachung
          von Betroffenenrechten weisen wir darauf hin, dass diese am
          effektivsten bei den Anbietern geltend gemacht werden können. Nur die
          Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können
          direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten
          Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.<br /><br />
        </li>
        <li>
          <strong>Zweck der Verarbeitung:</strong> Kommunikation mit den auf den
          sozialen Netzwerken angeschlossenen und registrierten Nutzern;
          Information und Werbung für unsere Produkte, Angebote und
          Dienstleistungen; Außerdarstellung und Imagepflege; Auswertung und
          Analyse der Nutzer und Inhalte unserer Präsenzen in den sozialen
          Medien.<br /><br />
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Die Rechtsgrundlage für die
          Verarbeitung der personenbezogenen Daten ist unser in den obigen
          Zwecken liegendes berechtigtes Interesse gemäß Art. 6 Abs. 1 S. 1 lit.
          f) DS-GVO. Soweit Sie uns bzw. dem Verantwortlichen des sozialen
          Netzwerks eine Einwilligung in die Verarbeitung Ihrer
          personenbezogenen Daten erteilt haben, ist Rechtsgrundlage Art. 6 Abs.
          1 S. 1 lit. a) i.V.m. Art. 7 DS-GVO.<br /><br />
        </li>
        <li>
          <strong>Datenübermittlung/Empfängerkategorie:</strong> Soziales
          Netzwerk.<br /><br />
        </li>
        <li>
          Die Datenschutzhinweise, Auskunftsmöglichkeiten und
          Widerspruchmöglichkeiten (Opt-Out) der jeweiligen Netzwerke /
          Diensteanbieter finden Sie hier:<br /><br />
          • <strong>Facebook</strong> – Diensteanbieter: Facebook Ireland Ltd.,
          4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland); Website:
          <a href="http://www.facebook.com/" rel="nofollow" target="_blank"
            >www.facebook.com</a
          >; Datenschutzerkl&auml;rung:&nbsp;<a
            href="https://www.facebook.com/about/privacy/"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >https://www.facebook.com/about/privacy/</a
          >, Opt-Out:&nbsp;<a
            href="https://www.facebook.com/settings?tab=ads"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >https://www.facebook.com/settings?tab=ads</a
          >&nbsp;und&nbsp;<a
            href="http://www.youronlinechoices.com/"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >http://www.youronlinechoices.com</a
          >; Widerspruch:
          <a
            href="https://www.facebook.com/help/contact/2061665240770586"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >https://www.facebook.com/help/contact/2061665240770586</a
          >; Vereinbarung &uuml;ber gemeinsame Verarbeitung personenbezogener
          Daten auf Facebook-Seiten (Art. 26 DS-GVO):
          <a
            href="https://www.facebook.com/legal/terms/page_controller_addendum"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >https://www.facebook.com/legal/terms/page_controller_addendum</a
          >, Datenschutzhinweise f&uuml;r Facebook-Seiten:
          <a
            href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >https://www.facebook.com/legal/terms/information_about_page_insights_data</a
          >.<br />Wir sind mit Facebook gemeinsam nach Art. 26 DSGVO für unsere
          Fanpage verantwortlich. Dazu wurde eine Vereinbarung namens
          "Informationen zu Seiten-Insights", abrufbar unter
          <span class="text-[0.5rem] sm:text-base">
            https://www.facebook.com/legal/terms/page_controller_addendum,
          </span>
          abgeschlossen, nach der Facebook bestimmte Sicherheitsmaßnahmen
          beachten muss und auch die Betroffenenrechte direkt selbst erfüllen
          wird. Sie können sich also vor allem auch direkt an Facebook wegen
          Auskunftsrechten und Löschungen wenden. Ihre Betroffenenrechte, wie
          vor allem Auskunft, Löschung, Widerspruch und Beschwerde bei der
          zuständigen Aufsichtsbehörde, werden dadurch aber nicht berührt.
          Weitere Informationen zu der gemeinsamen Verantwortung finden Sie in
          den "Informationen zu Seiten-Insights-Daten" unter
          <span class="text-[0.5rem] sm:text-base">
            https://www.facebook.com/legal/terms/information_about_page_insights_data.<br /><br />
          </span>
          • <strong>Instagram</strong> – Diensteanbieter: Facebook Ireland Ltd.,
          4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) &ndash;
          Datenschutzerkl&auml;rung/ Opt-Out:&nbsp;
          <a
            href="https://help.instagram.com/519522125107875"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >https://help.instagram.com/519522125107875</a
          >, Widerspruch:
          <a
            href="https://help.instagram.com/contact/186020218683230"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >https://help.instagram.com/contact/186020218683230</a
          >; Vereinbarung &uuml;ber gemeinsame Verarbeitung personenbezogener
          Daten auf Instagram-Seiten (Art. 26 DS-GVO):
          <a
            href="https://www.facebook.com/legal/terms/page_controller_addendum"
            rel="nofollow"
            target="_blank"
            class="text-[0.5rem] sm:text-base"
            >https://www.facebook.com/legal/terms/page_controller_addendum</a
          >.<br /><br />
          • <strong>Twitter</strong> – Diensteanbieter: Twitter Inc., 1355
          Market Street, Suite 900, San Francisco, CA 94103, USA) -
          Datenschutzerkl&auml;rung:&nbsp;<a
            href="https://twitter.com/de/privacy"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://twitter.com/de/privacy</a
          >, Opt-Out:&nbsp;<a
            href="https://twitter.com/personalization"
            target="_blank"
            rel="nofollow"
            >https://twitter.com/personalization</a
          ><br /><br />
          • <strong>XING</strong> – Diensteanbieter: XING AG,
          Dammtorstra&szlig;e 29-32, 20354 Hamburg, Deutschland) -
          Datenschutzerkl&auml;rung/ Opt-Out:&nbsp;<a
            href="https://privacy.xing.com/de/datenschutzerklaerung"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://privacy.xing.com/de/datenschutzerklaerung</a
          >.<br /><br />
          • <strong>LinkedIn</strong> – Diensteanbieter: LinkedIn Ireland
          Unlimited Company, Wilton Place, Dublin 2, Irland) &ndash;
          Datenschutzerkl&auml;rung:&nbsp;<a
            href="https://www.linkedin.com/legal/privacy-policy"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://www.linkedin.com/legal/privacy-policy</a
          >, Cookie-Richtlinie und Opt-Out:&nbsp;<a
            href="https://www.linkedin.com/legal/cookie-policy"
            target="_blank"
            rel="nofollow"
            class="text-[0.5rem] sm:text-base"
            >https://www.linkedin.com/legal/cookie-policy</a
          >.<br /><br />
        </li>
      </ol>

      <br />
      <strong>Datenschutz bei Bewerbungen und im Bewerberverfahren</strong>

      <ol style="margin: 10px 0px; padding: 15px">
        <li>
          Bewerbungen, die auf elektronischem Wege oder per Post an den
          Verantwortlichen gesendet werden, werden zum Zwecke der Abwicklung des
          Bewerberverfahrens elektronisch oder manuell verarbeitet.<br /><br />
        </li>
        <li>
          Wir weisen ausdrücklich darauf hin, dass Bewerbungsunterlagen mit
          „besonderen Kategorien personenbezogener Daten“ nach Art. 9 DS-GVO
          (z.B. ein Foto, welches Rückschlüsse auf Ihre ethnische Herkunft,
          Religion oder Ihren Familienstand gibt), mit Ausnahme einer
          eventuellen Schwerbehinderung, welche Sie aus freier Entscheidung
          offenlegen möchten, unerwünscht sind. Sie sollen Ihre Bewerbung ohne
          diese Daten einreichen. Dies hat keine Auswirkungen auf Ihre
          Bewerberchancen.<br /><br />
        </li>
        <li>
          Rechtsgrundlagen für die Verarbeitung sind Art. 6 Abs. 1 S.1 lit. b)
          DS-GVO sowie § 26 BDSG n.F.<br /><br />
        </li>
        <li>
          Wird nach Abschluss des Bewerberverfahrens, ein Arbeitsverhältnis mit
          dem Bewerber / der Bewerberin eingegangen, werden die Bewerberdaten
          unter Beachtung einschlägiger Datenschutzvorschriften gespeichert.
          Wird Ihnen nach Abschluss des Bewerberverfahrens keine Stelle
          angeboten, so wird Ihr eingereichtes Bewerbungsschreiben samt
          Unterlagen 6 Monate nach Versand der Absage gelöscht, um etwaigen
          Ansprüchen und Nachweispflichten nach AGG genügen zu können.<br /><br />
        </li>
      </ol>

      <br />
      <strong>Rechte der betroffenen Person</strong>

      <ol style="margin: 10px 0px; padding: 15px">
        <li>
          <strong
            >Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten<br /><br />
            Soweit die Verarbeitung auf Ihrer Einwilligung gemäß Art. 6 Abs. 1
            S. 1 lit. a), Art. 7 DS-GVO beruht, haben Sie das Recht, die
            Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der
            aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
            wird dadurch nicht berührt.<br /><br />
            Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die
            Interessenabwägung gemäß Art. 6 Abs. 1 S. 1 lit. f) DS-GVO stützen,
            können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der
            Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines
            Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der
            nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei
            Ausübung eines solchen Widerspruchs bitten wir um Darlegung der
            Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns
            durchgeführt verarbeiten sollten. Im Falle Ihres begründeten
            Widerspruchs prüfen wir die Sachlage und werden entweder die
            Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere
            zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die
            Verarbeitung fortführen.<br /><br />
            Sie können der Verarbeitung Ihrer personenbezogenen Daten für Zwecke
            der Werbung und Datenanalyse jederzeit widersprechen. Das
            Widerspruchsrecht können Sie kostenfrei ausüben. Über Ihren
            Werbewiderspruch können Sie uns unter folgenden Kontaktdaten
            informieren:<br /><br />

            ChauTech Consulting GmbH<br />Lachnerstraße 1a<br />22083 Hamburg<br />Geschäftsführer
            Shuhaib Chaudhry <br />Handelsregister/Nr.: HRB 184844<br />Registergericht:
            Amtsgericht Hamburg<br />E-Mail-Adresse: info@rechtweber.de<br
          /></strong>
          <br />
        </li>
        <li>
          <strong>Recht auf Auskunft</strong><br />
          Sie haben ein Recht auf Auskunft über Ihre bei uns gespeicherten
          persönlichen Daten nach Art. 15 DS-GVO. Dies beinhaltet insbesondere
          die Auskunft über die Verarbeitungszwecke, die Kategorie der
          personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
          denen Ihre Daten offengelegt wurden oder werden, die geplante
          Speicherdauer, die Herkunft ihrer Daten, sofern diese nicht direkt bei
          Ihnen erhoben wurden.<br /><br />
        </li>
        <li>
          <strong>Recht auf Berichtigung</strong><br />
          Sie haben ein Recht auf Berichtigung unrichtiger oder auf
          Vervollständigung richtiger Daten nach Art. 16 DS-GVO.
          <br /><br />
        </li>
        <li>
          <strong>Recht auf Löschung</strong><br />
          Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten Daten
          nach Art. 17 DS-GVO, es sei denn gesetzliche oder vertraglichen
          Aufbewahrungsfristen oder andere gesetzliche Pflichten bzw. Rechte zur
          weiteren Speicherung stehen dieser entgegen.
          <br /><br />
        </li>
        <li>
          <strong>Recht auf Einschränkung</strong><br />
          Sie haben das Recht, eine Einschränkung bei der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, wenn eine der Voraussetzungen in
          Art. 18 Abs. 1 lit. a) bis d) DS-GVO erfüllt ist:<br />
          • Wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für
          eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
          Richtigkeit der personenbezogenen Daten zu überprüfen;<br /><br />
          • die Verarbeitung unrechtmäßig ist und Sie die Löschung der
          personenbezogenen Daten ablehnen und stattdessen die Einschränkung der
          Nutzung der personenbezogenen Daten verlangen;<br /><br />
          • der Verantwortliche die personenbezogenen Daten für die Zwecke der
          Verarbeitung nicht länger benötigt, Sie diese jedoch zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          benötigen, oder<br /><br />
          • wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
          DS-GVO eingelegt haben und noch nicht feststeht, ob die berechtigten
          Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.<br /><br />
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit</strong><br />
          Sie haben ein Recht auf Datenübertragbarkeit nach Art. 20 DS-GVO, was
          bedeutet, dass Sie die bei uns über Sie gespeicherten
          personenbezogenen Daten in einem strukturierten, gängigen und
          maschinenlesbaren Format erhalten können oder die Übermittlung an
          einen anderen Verantwortlichen verlangen können.
          <br /><br />
        </li>
        <li>
          <strong>Recht auf Beschwerde</strong><br />
          Sie haben ein Recht auf Beschwerde bei einer Aufsichtsbehörde. In der
          Regel können Sie sich hierfür an die Aufsichtsbehörde insbesondere in
          dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des
          Orts des mutmaßlichen Verstoßes wenden.
          <br /><br />
        </li>
      </ol>

      <br />
      <strong>Datensicherheit</strong>
      <p>
        Um alle personenbezogen Daten, die an uns übermittelt werden, zu
        schützen und um sicherzustellen, dass die Datenschutzvorschriften von
        uns, aber auch unseren externen Dienstleistern eingehalten werden, haben
        wir geeignete technische und organisatorische Sicherheitsmaßnahmen
        getroffen. Deshalb werden unter anderem alle Daten zwischen Ihrem
        Browser und unserem Server über eine sichere SSL-Verbindung
        verschlüsselt übertragen.
      </p>
      <br />

      <br />
      <strong>Stand: 30.01.2024</strong>

      <p>
        Quelle:
        <a href="https://www.juraforum.de/datenschutzerklaerung-muster/"
          >Zur Quelle</a
        >
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import AppButton from "@/components/common/AppButton.vue";
import Divider from "@/components/common/Divider.vue";

const router = useRouter();

const goToHome = () => {
  router.push("/");
};
</script>
