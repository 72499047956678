<template>
  <div class="flex h-fit w-full justify-center">
    <div
      class="flex w-10/12 flex-col-reverse items-center justify-center lg:flex-row"
      v-aos="{
        animation: 'fade-right',
        anchorPlacement: 'top-center',
        duration: 1000,
      }"
    >
      <div
        class="management-card bg-green-100 p-[2.5rem] lg:mr-[-3rem] lg:w-3/4 lg:p-12 lg:pr-20"
      >
        <p class="text-justify font-bold">
          {{ $t("management.profile") }}
        </p>
        <h4 class="mt-4 text-logo-green">Shuhaib Chaudhry</h4>
        <p class="font-bold text-logo-green">Managing Director</p>
        <p class="font-bold text-logo-green">ChauTech Consulting GmbH</p>
      </div>
      <div
        class="management-image z-10 mb-[-2rem] h-72 w-72 rounded-3xl bg-slate-300 p-8 lg:z-0 lg:mb-0 lg:h-4/5 xl:h-[90%]"
        v-aos="{
          animation: 'fade-left',
          anchorPlacement: 'top-center',
          duration: 1000,
        }"
      ></div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.management-card {
  border-radius: 5rem;
}

.management-image {
  background-image: url("../../assets/images/team/shu.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
