import { createApp } from "vue";
import VueClickAway from "vue3-click-away";
import AOS from "aos";
import "aos/dist/aos.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./tailwind.css";
import i18n from "./config/i18nConfig";
import AOSDirective from "./directives/AOSDirective";

AOS.init();

const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n);
app.use(VueClickAway);
app.use(AOSDirective);

app.mount("#app");
