<template>
  <div
    class="mr-4 flex h-8 w-9 items-center justify-center rounded-md hover:cursor-pointer hover:bg-gray-200"
    @click="toggleDarkMode"
  >
    <button
      v-if="!darkThemeEnabled"
      class="group flex items-center font-medium text-gray-600 hover:text-blue-600 dark:text-gray-400 dark:hover:text-gray-500"
    >
      <svg
        class="h-4 w-4 flex-shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z" />
      </svg>
    </button>
    <button
      v-else
      class="flex items-center font-medium text-gray-600 hover:text-blue-600 dark:text-gray-400 dark:hover:text-gray-500"
    >
      <svg
        class="h-4 w-4 flex-shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="4" />
        <path d="M12 8a2 2 0 1 0 4 4" />
        <path d="M12 2v2" />
        <path d="M12 20v2" />
        <path d="m4.93 4.93 1.41 1.41" />
        <path d="m17.66 17.66 1.41 1.41" />
        <path d="M2 12h2" />
        <path d="M20 12h2" />
        <path d="m6.34 17.66-1.41 1.41" />
        <path d="m19.07 4.93-1.41 1.41" />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";

const darkThemeEnabled = ref(false);

const toggleDarkMode = () => {
  darkThemeEnabled.value = !darkThemeEnabled.value;
  // Update dark mode preference only if user consent is given
  if (userConsentedToCookies()) {
    localStorage.setItem("darkMode", `${darkThemeEnabled.value}`);
  }
  updateDarkMode();
};

const updateDarkMode = () => {
  if (darkThemeEnabled.value) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
};

// Check for user consent
const userConsentedToCookies = (): boolean => {
  const consent = localStorage.getItem("cookieConsent");
  return consent === "true";
};

onMounted(() => {
  if (userConsentedToCookies()) {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    darkThemeEnabled.value = isDarkMode;
    updateDarkMode();
  }
});

// Watch for changes in darkThemeEnabled and update the class accordingly
watch(darkThemeEnabled, (newValue) => {
  updateDarkMode();
});
</script>
