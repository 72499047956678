<template>
  <div>
    <div class="grid gap-2 sm:grid-cols-3 sm:gap-4 md:gap-8 lg:gap-20">
      <div class="image-container">
        <img
          class="image"
          src="../../../assets/images/team/mehran.jpg"
          alt="team-lead"
        />
        <div class="mt-4 text-center">
          <h3>Mehran Rasheed</h3>
          <p class="font-bold">Technical Lead</p>
          <p class="font-bold">Cloud Consultant</p>
        </div>
      </div>
      <div class="image-container sm:pt-20">
        <img
          class="image"
          src="../../../assets/images/team/heiko.jpg"
          alt="team-lead"
        />
        <div class="mt-4 text-center">
          <h3>Hendrik Middelschulte</h3>
          <p class="font-bold">Head of SAP</p>
        </div>
      </div>
      <div class="image-container sm:pt-40">
        <img
          class="image"
          src="../../../assets/images/team/andreas.jpg"
          alt="team-lead"
        />
        <div class="mt-4 text-center">
          <h3>Andreas Heidemann</h3>
          <p class="font-bold">Head of Contract Management</p>
          <p class="font-bold">{{ $t("team.andreas.partner") }}</p>
          <p class="font-bold">{{ $t("team.andreas.title") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.image {
  border-radius: 5rem 0.625rem;
  @apply mt-8 sm:mt-0;
}

.image-container {
  transition: transform 0.9s;
}

.image-container:hover {
  transform: translateY(-1rem);
}
</style>
