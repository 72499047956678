<template>
  <div>
    <Header @scrollTo="handleScrollTo" />
    <Hero />
    <section id="about-us">
      <AboutUs />
    </section>
    <section id="services">
      <Services />
    </section>
    <section id="contract">
      <ContractTypes />
    </section>
    <section id="team">
      <Management />
      <Team />
    </section>
    <ContactUs />
    <BackToTop />
    <CookieConsentBanner />
  </div>
</template>

<script setup lang="ts">
import Header from "@/components/header/Header.vue";
import Hero from "@/components/hero/Hero.vue";
import AboutUs from "@/components/about-us/AboutUs.vue";
import Services from "@/components/services/Services.vue";
import ContractTypes from "@/components/contract/ContractTypes.vue";
import ContactUs from "@/components/ContactUs.vue";
import Management from "@/components/management/Management.vue";
import Team from "@/components/management/team/Team.vue";
import BackToTop from "@/components/common/BackToTop.vue";
import CookieConsentBanner from "@/components/CookieConsentBanner.vue";

const handleScrollTo = (sectionId: string) => {
  const section = document.getElementById(sectionId);
  if (section) {
    smoothScrollTo(section);
  }
};

const smoothScrollTo = (element: Element) => {
  const targetPosition = element.getBoundingClientRect().top;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  const duration = 1500;
  let start = 0;

  window.requestAnimationFrame(step);

  function step(timestamp: number) {
    if (!start) start = timestamp;
    const progress = timestamp - start;
    window.scrollTo(
      0,
      easeInOutCubic(progress, startPosition, distance, duration),
    );
    if (progress < duration) window.requestAnimationFrame(step);
  }

  function easeInOutCubic(t: number, b: number, c: number, d: number) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
  }
};
</script>

<style scoped>
@keyframes scaleUp {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate.scale-up {
  animation: scaleUp 1s ease-in-out;
}
</style>
