<template>
  <div
    class="flex items-center justify-center"
    v-aos="{
      animation: 'fade-up',
      anchorPlacement: 'top-center',
      duration: 2000,
    }"
  >
    <div
      class="flex w-[95%] flex-col items-center justify-center gap-3 rounded-3xl bg-green-50 p-10 shadow-md sm:w-4/5 lg:w-[63%]"
    >
      <div class="mb-12 w-[50%]">
        <img
          src="../../assets/images/contract/contract.png"
          alt="contract-logo"
        />
      </div>
      <div class="relative">
        <img
          class="w-[40rem]"
          src="../../assets/images/contract/contract-types.png"
          alt="contract-types"
        />
        <div class="services">
          <span>
            <h4 class="heading">{{ $t("contract.service.type") }}</h4>
            <p class="text">
              {{ $t("contract.service.pre-text") }} <br />
              {{ $t("contract.service.post-text") }}
            </p>
          </span>
        </div>
        <div class="temp-employment">
          <span>
            <h4 class="temp-heading">{{ $t("contract.transfer.type") }}</h4>
            <p class="temp-text">
              {{ $t("contract.transfer.pre-text") }} <br />
              {{ $t("contract.transfer.post-text") }}
            </p>
          </span>
        </div>
        <div class="perm">
          <span>
            <h4 class="perm-heading">{{ $t("contract.temp.type") }}</h4>
            <p class="perm-text">
              {{ $t("contract.temp.pre-text") }} <br />
              {{ $t("contract.temp.post-text") }}
            </p>
          </span>
        </div>
        <div class="fest">
          <span>
            <h4 class="fest-heading">{{ $t("contract.perm.type") }}</h4>
            <p class="fest-text">
              {{ $t("contract.perm.text") }}
            </p>
          </span>
        </div>
        <div class="gewerk">
          <span>
            <h4 class="g-heading">{{ $t("contract.trade.type") }}</h4>
            <p class="g-text">
              {{ $t("contract.trade.text") }}
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.services {
  @apply absolute left-[10%] top-[2%] xxxxs:left-[16%] xxxxs:top-[4%] md:left-[13%];
}

.heading {
  @apply text-base xxxs:text-xl xxs:text-2xl md:text-3xl;
}

.text {
  @apply text-xs xxxxs:text-sm xxxs:w-72 xxs:w-full md:text-xl;
}

.temp-employment {
  @apply absolute left-[15%] top-[18%] xxxs:top-[20%] xxs:left-[16%] xxs:top-[21%] md:left-[15%];
}

.temp-heading {
  @apply text-base xxxs:text-xl xxs:text-2xl md:text-3xl;
}

.temp-text {
  @apply text-xs xxxxs:text-[0.8125rem] xxxs:w-72 xxxs:text-sm xxs:w-full md:w-[90%] md:text-[1.22rem] lg:w-full lg:text-xl;
}

.perm {
  @apply absolute left-[12%] top-[39%] xxxs:top-[40%] xxs:top-[42%];
}

.perm-heading {
  @apply text-base xxxs:text-xl xxs:text-2xl md:text-3xl;
}

.perm-text {
  @apply w-[90%] text-xs xxxxs:text-sm xxxs:w-[95%] xxs:w-full md:text-[1.22rem] lg:text-xl;
}

.fest {
  @apply absolute bottom-[30%] left-[15%] xxxs:bottom-[32%] md:bottom-[31%] md:left-[18%];
}

.fest-heading {
  @apply text-base xxxs:text-xl xxs:text-2xl md:text-3xl;
}

.fest-text {
  @apply w-56 text-xs xxxxs:text-sm md:w-80 md:text-xl;
}

.gewerk {
  @apply absolute bottom-[8%] left-[15%] xxxs:bottom-[9%] xxs:bottom-[10%] md:bottom-[12%] md:left-[9%];
}

.g-heading {
  @apply text-base xxxs:text-xl xxs:text-2xl md:text-3xl;
}

.g-text {
  @apply w-56 text-xs xxxxs:text-sm xxs:w-72 md:w-[90%] md:text-xl;
}
</style>
