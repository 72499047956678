import { createI18n } from "vue-i18n";

import en from "@/translations/en.json";
import de from "@/translations/de.json";
import { LANG } from "@/utils/types";

const i18n = createI18n({
  locale: LANG.DE,
  fallbackLocale: LANG.DE,
  messages: {
    en,
    de,
  },
  legacy: false,
});

export default i18n;
